import { GET_BROKER_COMPENSATION_LINK_ACTION } from 'actions/additionalDisclosures/getBrokerCompensationLink';

export const brokerCompensationLink = (
    state = '',
    action: {
        data?: string;
        type?: string;
    } = {}
) => {
    if (action.type === GET_BROKER_COMPENSATION_LINK_ACTION.success) {
        return action.data;
    }
    return state;
};
